import { defineMessages } from '@sevenrooms/core/locales'

export const spacesMessages = defineMessages({
  pageTitle: {
    id: 'groupBookings.pageTitle',
    defaultMessage: 'Group Bookings',
  },
  createNewSpace: {
    id: 'groupBookings.createNewSpace',
    defaultMessage: 'Create New Space',
  },
  loadingSpaces: {
    id: 'groupBookings.loadingSpaces',
    defaultMessage: 'Loading Spaces',
  },
  noSpacesTitle: {
    id: 'groupBookings.noSpacesTitle',
    defaultMessage: 'No Spaces have been created yet',
  },
  emptySpacesNoData: {
    id: 'groupBookings.emptySpacesNoData',
    defaultMessage: 'Get started by <a>creating new Spaces</a>',
  },
  draftsTitle: {
    id: 'groupBookings.draftsTitle',
    defaultMessage: 'Drafts',
  },
  inactiveTitle: {
    id: 'groupBookings.inactiveTitle',
    defaultMessage: 'Inactive',
  },
  activeTitle: {
    id: 'groupBookings.activeTitle',
    defaultMessage: 'Active',
  },
  activeDescription: {
    id: 'groupBookings.activeDescription',
    defaultMessage: 'Group Booking Spaces will be displayed in the Reservation Widget only.',
  },
  createdBy: {
    id: 'groupBookings.createdBy',
    defaultMessage: 'Created by: {userName} on {createdOn}',
  },
  attachedAccessRules: {
    id: 'groupBookings.attachedAccessRules',
    defaultMessage: `{count, plural,
      =0 {No Access Rules attached}
      one {1 Access Rule attached}
      other {{count} Access Rules attached}
    }`,
  },
  createSpace: {
    id: 'groupBookings.createSpace',
    defaultMessage: 'Create Space',
  },
  draft: {
    id: 'groupBookings.draft',
    defaultMessage: 'Draft',
  },
  spacesTitle: {
    id: 'groupBookings.spacesTitle',
    defaultMessage: 'Spaces',
  },
} as const)
