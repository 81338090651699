import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useGetExperienceQuery,
  useCreateExperienceMutation,
  useEditExperienceMutation,
  useGetAccessRulesListQuery,
} from '@sevenrooms/core/api'
import { ExperienceStatusEnum, OfferTypeEnum } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation, useLocation, useScrollToTop } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Form, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import {
  BaseSection,
  Box,
  HStack,
  Loader,
  notify,
  Flex,
  VStack,
  Breadcrumbs,
  Window,
  UnsavedChangesModal,
} from '@sevenrooms/core/ui-kit/layout'
import { Header, StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { PDR } from '@sevenrooms/mgr-offers/experiences/components/PDR/PDR'
import {
  type CreateEditExperienceFormData,
  formToExperience,
  useCreateEditExperienceForm,
  useDefaultValues,
} from '@sevenrooms/mgr-offers/experiences/CreateEditExperienceForm.zod'
import { ExperiencesLocales } from '@sevenrooms/mgr-offers/experiences/Experiences.locales'
import { ExperiencesActionButtons } from '@sevenrooms/mgr-offers/experiences/ExperiencesActionButtons'
import { spacesMessages } from '../spaces.locales'

export function SpaceForm() {
  const { formatMessage } = useLocales()
  const { venue, venueId, venueKey } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const nav = useNavigation()
  const { pathname } = useLocation()
  useScrollToTop(pathname)
  const { spaceId } = nav.matchParams(routes.manager2.marketing.groupBookings.editSpace) ?? {}

  const [stayInPage, setStayInPage] = useState(false)
  const [isImageUploading, setImageUploading] = useState(false)
  const [showExitWithoutChangesModal, setShowExitWithoutChangesModal] = useState(false)

  const { data: experience, isLoading: isExperienceLoading } = useGetExperienceQuery(
    spaceId ? { venueId, experienceId: spaceId } : skipToken
  )
  const { data: accessRuleData, isLoading: isAccessRuleLoading } = useGetAccessRulesListQuery(experience ? { venueId } : skipToken)
  const [createExperience, { isLoading: isCreateExperienceLoading }] = useCreateExperienceMutation()
  const [updateExperience, { isLoading: isEditExperienceLoading }] = useEditExperienceMutation()

  const pageTitleText = useMemo(
    () => (experience ? experience.name : formatMessage(spacesMessages.createSpace)),
    [experience, formatMessage]
  )

  const statusLabel = useMemo(() => {
    if (experience?.isDraft) {
      return <StatusLabel variant="status">{formatMessage(spacesMessages.draft)}</StatusLabel>
    }
    if (experience?.isActive) {
      return <StatusLabel variant="success">{formatMessage(commonMessages.active)}</StatusLabel>
    }
    if (experience?.isInactive) {
      return <StatusLabel variant="inactive">{formatMessage(commonMessages.inactive)}</StatusLabel>
    }
    return null
  }, [experience, formatMessage])

  const createEditFormSchema = useCreateEditExperienceForm()
  const defaultValues = useDefaultValues(experience, venueSettings)
  const form = useForm(createEditFormSchema, { mode: 'onSubmit' })
  const {
    field,
    reset,
    formState: { dirtyFields, isDirty },
    setValue,
  } = form

  useEffect(() => {
    reset(defaultValues)
    field.prop('offerType').set(OfferTypeEnum.PDR)
  }, [reset, defaultValues, field])

  const handleOnSubmit = useCallback(
    async (createEditExperienceFormData: CreateEditExperienceFormData) => {
      try {
        if (experience) {
          setImageUploading(true)
          const updateExperienceData = await formToExperience({
            createEditExperienceFormData,
            dirtyFields,
            experience,
            onError: () => {
              notify({
                content: formatMessage(ExperiencesLocales.experienceAPIError),
                type: 'error',
              })
            },
          })
          setImageUploading(false)
          await updateExperience({
            experience: updateExperienceData,
            experienceId: experience.id,
            venueId: venue.id,
          }).unwrap()
          notify({
            content: formatMessage(ExperiencesLocales.experienceUpdated, { name: createEditExperienceFormData.offerName }),
            type: 'success',
          })
          if (!stayInPage) {
            nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey: venue.urlKey } })
          }
        } else {
          setImageUploading(true)
          const createExperienceData = await formToExperience({
            createEditExperienceFormData,
            dirtyFields,
            experience,
            onError: () => {
              notify({
                content: formatMessage(ExperiencesLocales.experienceAPIError),
                type: 'error',
              })
            },
          })
          setImageUploading(false)
          const createdExperience = await createExperience({
            experience: createExperienceData,
            venueId: venue.id,
          }).unwrap()
          notify({
            content: formatMessage(ExperiencesLocales.experienceCreated, { name: createEditExperienceFormData.offerName }),
            type: 'success',
          })
          if (!stayInPage) {
            nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey: venue.urlKey } })
          } else {
            nav.push(routes.manager2.marketing.groupBookings.editSpace, {
              params: { venueKey: venue.urlKey, spaceId: createdExperience.id },
            })
          }
        }
      } catch {
        notify({
          content: formatMessage(ExperiencesLocales.experienceAPIError),
          type: 'error',
        })
      }
      reset({}, { keepValues: true })
    },
    [createExperience, dirtyFields, experience, formatMessage, nav, reset, stayInPage, updateExperience, venue.id, venue.urlKey]
  )

  const handleOnInvalid = useCallback(
    err =>
      // eslint-disable-next-line no-console
      console.error(err),
    []
  )

  const isLoading = isExperienceLoading || isCreateExperienceLoading || isEditExperienceLoading || isImageUploading || isAccessRuleLoading

  return isLoading ? (
    <Loader />
  ) : (
    <Box data-test="create-or-edit-space-page">
      <SettingsPageMeta venue={venue?.name} title={pageTitleText} />
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={handleOnInvalid}>
        <SettingsPageContent
          breadcrumbs={
            <Breadcrumbs>
              <Button
                data-test="space-title-button"
                variant="tertiary"
                noPadding
                onClick={() => {
                  if (isDirty) {
                    setShowExitWithoutChangesModal(true)
                  } else {
                    nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey } })
                  }
                }}
              >
                {formatMessage(spacesMessages.spacesTitle)}
              </Button>
              <Text>{pageTitleText}</Text>
            </Breadcrumbs>
          }
          title={
            <HStack spacing="s">
              <Header type="h1">{pageTitleText}</Header>
              {statusLabel}
            </HStack>
          }
          actions={
            <ExperiencesActionButtons
              disabled={false}
              onCancel={() => nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey } })}
              experienceId={experience?.id}
              isActive={experience?.isActive}
              isInActive={experience?.isInactive}
              setStayInPage={setStayInPage}
              isPDR
              onSaveAndPublish={() => {
                setValue('status', ExperienceStatusEnum.ACTIVE)
              }}
              onDeactivate={() => {
                setValue('status', ExperienceStatusEnum.INACTIVE)
              }}
              onSaveAsDraft={() => {
                setValue('status', ExperienceStatusEnum.DRAFT)
              }}
            />
          }
        >
          <VStack pl="lm" spacing="lm" pb="lm">
            <BaseSection>
              <HStack justifyContent="space-between" p="lm">
                <Flex flexDirection="column" flex="1">
                  <Label
                    primary={formatMessage(ExperiencesLocales.privateDiningName)}
                    secondary={formatMessage(ExperiencesLocales.privateDiningNameDescription)}
                  />
                  <FormInput field={field.prop('offerName')} placeholder={formatMessage(ExperiencesLocales.enterName)} fullWidth />
                </Flex>
              </HStack>
            </BaseSection>
            <PDR
              experience={experience}
              accessRuleData={accessRuleData}
              field={field}
              isTripleSeatDinewiseEnabled={!!venueSettings?.is_triple_seat_dinewise_enabled}
              isPDRDepositFeeEnabled={!!venueSettings?.is_pdr_deposit_fee_enabled}
            />
          </VStack>
        </SettingsPageContent>
      </Form>
      <Window active={showExitWithoutChangesModal}>
        <UnsavedChangesModal
          onCancel={() => setShowExitWithoutChangesModal(false)}
          onDiscard={() => nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey } })}
        />
      </Window>
    </Box>
  )
}
